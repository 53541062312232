import React from "react";
import "./Header.css";

const Header = ({ profileImage, psycheCount }) => {
  return (
    <header className="header">
      <img src={profileImage} alt="" className="profile-image" />
      <div className="psyche-count">{psycheCount} <img src="/icons/psyche.png" className="psyche-icon"/></div>
    </header>
  );
};

export default Header;