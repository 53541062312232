import React, { useState, useEffect } from "react";
import "./MyGallery.css";
import PictureDetail from "./PictureDetail";
import ClipLoader from "react-spinners/ClipLoader";

const MyGallery = ({ onBack }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Function to fetch images from the API with credentials
    const fetchImages = async () => {
      try {
        const response = await fetch("/api/users/gallery", {
          method: "GET",
          credentials: "include", // This ensures cookies are sent with the request
        });
        const data = await response.json();

        // Ensure data is an array
        if (Array.isArray(data)) {
          setImages(data);
          setIsLoading(false);
        } else {
          console.error("API response is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const renderGallery = () => (
    <>
      <div className="mypage_header">
        <button className="back-button" onClick={onBack}>
          ＜ マイページ
        </button>
      </div>
      <div className="mygallery">
        <h2>マイギャラリー</h2>
        {isLoading ? (
          <div className="sweet-loading">
            <ClipLoader color="#3700b3" />
          </div>
        ) : (
          <div className="gallery-container">
            {images.map((image, index) => (
              <div
                className="gallery-item"
                key={index}
                onClick={() => handleImageClick(image)}
              >
                <img
                  src={`data:image/png;base64,${image}`}
                  alt={`Gallery ${index}`}
                  className="gallery-image"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );

  return selectedImage ? (
    <PictureDetail
      image={selectedImage}
      onBack={() => setSelectedImage(null)}
    />
  ) : (
    renderGallery()
  );
};

export default MyGallery;
