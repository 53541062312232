import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EmailForm.css";

const validateEmail = (email) => {
  // メールアドレスのバリデーション関数
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

function EmailForm({ initialEmail }) {
  const [email, setEmail] = useState(initialEmail || "");
  const [confirmEmail, setConfirmEmail] = useState(initialEmail || "");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // メールアドレスの入力変更ハンドラー
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };

  // フォーム送信ハンドラー
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("*有効なメールアドレスを入力してください。");
      return;
    } else if (email !== confirmEmail) {
      setError("*メールアドレスが一致しません");
      return;
    }
    setError("");

    try {
      // APIリクエストの送信
      const response = await fetch("api/users/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 必要に応じて認証トークンやセッションIDをヘッダーに追加
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          setError("*認証に失敗しました。");
        } else if (response.status === 400) {
          setError("*無効なメールアドレスです。");
        } else {
          setError("*予期しないエラーが発生しました。");
        }
        throw new Error("Network response was not ok");
      }

      navigate("/main");
    } catch (error) {
      setError("メールアドレスの更新中にエラーが発生しました。");
    }
  };

  return (
    <div className="email-form-container">
      <div className="email-form-card">
        <h2 className="email-form-title">メールアドレス登録</h2>
        <p className="email-form-notice">
          メールアドレスは、本サービスにおいてトラブルが発生した際の連絡手段や迅速な対応を行う目的で利用されます
        </p>
        <form onSubmit={handleSubmit}>
          <div className="email-form-group">
            <label htmlFor="email" className="email-form-label"></label>
            <input
              type="email"
              id="email"
              className="email-form-control"
              value={email}
              onChange={handleEmailChange}
              placeholder="example@example.com" // placeholderを追加
              required
            />
          </div>
          <div className="email-form-group">
            <label htmlFor="confirm-email" className="email-form-label">
              確認用
            </label>
            <input
              type="email"
              id="confirm-email"
              className="email-form-control"
              value={confirmEmail}
              onChange={handleConfirmEmailChange}
              placeholder="メールアドレスを再入力" // placeholderを追加
              required
            />
          </div>
          {error && <p className="email-form-error-message">{error}</p>}
          <button type="submit" className="email-form-submit-button">
            送信
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailForm;
