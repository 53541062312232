import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MyPage.css";
import MyGallery from "./MyGallery";
import PsychePurchase from "./PsychePurchase";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

// お問合せフォーム（Google Form）へのURLを取得
const CONTACT_FORM_URL = process.env.REACT_APP_CONTACT_FORM_URL;

const MyPage = ({ setView }) => {
  const [currentView, setCurrentView] = useState("main");
  const [profileImage, setProfileImage] = useState("");
  const [userName, setUserName] = useState("");
  const [psycheCount, setPsycheCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("/api/users/detail", {
          withCredentials: true, // To send cookies with the request
        });
        const { user_name, picture_url, current_psyche } = response.data;
        setUserName(user_name);
        setProfileImage(picture_url);
        setPsycheCount(current_psyche);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = async () => {
    try {
      await fetch("/api/logout", {
        method: "POST",
      });
    } catch (error) {
      console.error("Error logging out:", error);
    }

    // Redirect to landing page
    navigate("/");
  };

  const renderMainPage = () => (
    <>
      <div className="profile-section">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <div className="profile-details">
          <div className="user-name">{userName}</div>
          <div className="psyche-count">
            <p>{psycheCount} プシケ </p>
            <img src="/icons/psyche.png" className="psyche-icon" />
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="action-item" onClick={() => setCurrentView("gallery")}>
          マイギャラリー
        </div>
        <div className="action-item" onClick={() => setCurrentView("purchase")}>
          プシケ購入
        </div>
        <div
          className="action-item"
          onClick={() => {
            window.open(CONTACT_FORM_URL, "_blank", "noopener,noreferrer");
          }}
        >
          お問い合わせ
        </div>
        <div className="action-item" onClick={handleLogout}>
          ログアウト
        </div>
      </div>
    </>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="sweet-loading">
          <ClipLoader color="#3700b3" />
        </div>
      );
    }
    if (error) {
      return <div>Error loading user details</div>;
    }
    if (currentView === "main") {
      return renderMainPage();
    } else if (currentView === "gallery") {
      return <MyGallery onBack={() => setCurrentView("main")} />;
    } else if (currentView === "purchase") {
      return (
        <PsychePurchase
          psycheCount={psycheCount}
          onBack={() => setCurrentView("main")}
        />
      );
    }
  };

  return <div className="mypage">{renderContent()}</div>;
};

export default MyPage;
