import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import MyPage from "./MyPage";
import MyGallery from "./MyGallery";
import PsychePurchase from "./PsychePurchase";
import ConsumePsycheForPicture from "./ConsumePsycheForPicture";
import "./Landing.css";
import axios from "axios";
import tutorialPopupImage from "../assets/images/tutorial-popup.png";

const CharacterCard = ({ image, name, onClick }) => {
  return (
    <div className="card-link" onClick={onClick}>
      <div className="card">
        <img src={image} alt={name} className="card-image" />
        <div className="card-name">{name}</div>
      </div>
    </div>
  );
};

const TutorialPopup = ({ onTakePictureNow, onTakeLater }) => {
  return (
    <div className="tutorial-popup">
      <div className="popup-content">
        <h2>ウェルカム撮影のご案内</h2>
        <img src={tutorialPopupImage} />
        <p>
          SoulShotへようこそ！
          <br />※ ウェルカム撮影時にプシケが消費されません。
        </p>
        <div className="popup-buttons">
          <button onClick={onTakePictureNow}>今撮ってみる</button>
          <button onClick={onTakeLater}>後で</button>
        </div>
      </div>
    </div>
  );
};

const MainPage = () => {
  const [activeTab, setActiveTab] = useState("写真撮影");
  const [view, setView] = useState("main");
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [psycheCount, setPsycheCount] = useState(0);
  const [showTutorialPopup, setShowTutorialPopup] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [showMaintenanceNotice, setShowMaintenanceNotice] = useState(true);

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const response = await fetch("/api/souls");
        const data = await response.json();

        const mappedCharacters = data.map((character) => ({
          name: character.soul_name,
          image: character.picture_url,
          soul_id: character.soul_id,
          sekai_info: character.sekai_info,
        }));
        setCharacters(mappedCharacters);
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    };

    fetchCharacters();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("/api/users/detail", {
          withCredentials: true,
        });
        const { picture_url, current_psyche, tutorial_consumed } =
          response.data;
        setProfileImage(picture_url);
        setPsycheCount(current_psyche);
        if (!tutorial_consumed) {
          setShowTutorialPopup(true);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await fetch("/api/maintenance_check");
        const data = await response.json();
        setIsMaintenance(data.is_maintenance);
      } catch (error) {
        console.error("Error fetching maintenance status:", error);
        setIsMaintenance(true);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
    setView("consumePsyche");
  };

  const handleClose = () => {
    setShowMaintenanceNotice(false);
  };

  useEffect(() => {
    if (activeTab === "マイページ") {
      setView("main"); // Reset view when changing tab to マイページ
    }
  }, [activeTab]);

  const handleTakePictureNow = () => {
    const sashaCharacter = characters.find(
      (character) => character.soul_id === "sasha_hughug"
    );
    if (sashaCharacter) {
      handleCharacterClick(sashaCharacter);
    }
    setShowTutorialPopup(false);
  };

  const renderContent = () => {
    if (view === "consumePsyche") {
      return (
        <ConsumePsycheForPicture
          character={selectedCharacter}
          onBack={() => setView("main")}
        />
      );
    }

    if (activeTab === "写真撮影") {
      return (
        <div className="page-main-container">
          <p className="limited-time-text">
            期間限定！夏休みイベント
            <br />
            水着姿のソウルたちと写真を撮るチャンス！
            <p className="time-limit">8/31まで</p>
            <p className="limited-time-explanation">
              ソウル選択後に水着オプションが選択できます
            </p>
          </p>
          <div className="soul-array-main-container">
            {characters.map((character) => (
              <CharacterCard
                key={character.name}
                name={character.name}
                image={character.image}
                onClick={() => handleCharacterClick(character)}
              />
            ))}
          </div>
        </div>
      );
    } else if (activeTab === "マイページ") {
      return view === "main" ? (
        <MyPage setView={setView} />
      ) : view === "gallery" ? (
        <MyGallery onBack={() => setView("main")} />
      ) : (
        <PsychePurchase
          psycheCount={psycheCount}
          onBack={() => setView("main")}
        />
      );
    }
  };

  return (
    <div className="main-page">
      {showTutorialPopup && (
        <TutorialPopup
          onTakePictureNow={handleTakePictureNow}
          onTakeLater={() => setShowTutorialPopup(false)}
        />
      )}
      {activeTab !== "マイページ" && (
        <>
          <Header profileImage={profileImage} psycheCount={psycheCount} />
          {isMaintenance && showMaintenanceNotice && (
            <div className="maintenance-notice">
              ただいまメンテナンス中です。<br/>
              ご迷惑をおかけして申し訳ございません。
              <button onClick={handleClose} className="close-button">×</button>
            </div>
          )}
        </>
      )}
      <div className="content">{renderContent()}</div>

      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
};

export default MainPage;
