import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import "./WaitingScreen.css";
import { useTranslation } from "react-i18next";

const WaitingScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const combinedImageSrc = location.state?.combinedImageSrc;
  // console.log(`StreamWidth: ${stream_width}, StreamHeight: ${stream_height}`);

  return (
    <div className="waiting-screen">
      <div className="image-container">
        {combinedImageSrc ? (
          <>
            <img
              src={combinedImageSrc}
              alt="Combined Image"
              className="combined-image"
            />
            <div className="loader"></div>
          </>
        ) : (
          <p>写真が正しく撮れてないようです。メイン画面に戻ってください！</p>
        )}
      </div>
      <div className="waiting-flex-container">
        <p className="waiting-instruction-message">
          {t("10~20秒ぐらい待ってね〜")}
        </p>
      </div>
    </div>
  );
};

export default WaitingScreen;
