import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import EmailForm from "./EmailForm";
import ClipLoader from "react-spinners/ClipLoader";

const LineCallback = () => {
  const urlPrams = new URLSearchParams(window.location.search);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isInitialLogin, setIsInitialLogin] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const handleError = () => {
    console.log(urlPrams.get("error"));
    setIsError(true);
  };

  useEffect(() => {
    if (urlPrams.has("error")) {
      handleError();
    } else {
      const code = urlPrams.get("code");
      const state = urlPrams.get("state");

      const jsonRequest = {
        code: code,
        state: state,
      };

      // アクセストークンを取得し、ユーザの情報を取得する
      fetch(`/api/login/line/callback?code=${code}&param_state=${state}`, {
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // レスポンスをJSONとしてパースする
        })
        .then((data) => {
          const isInitialLogin = data.is_initial_login;
          const emailAddress = data.email_address;

          // NOTE: 開発環境ではStrictModeになっているので、初期値が表示されません
          if (isInitialLogin) {
            setIsInitialLogin(true);
            setEmailAddress(emailAddress);
          } else {
            navigate("/main");
          }
        })
        .catch((error) => {
          // エラー処理
          handleError();
          alert("ログインに失敗しました。再度お試しください。");
          navigate("/");
        });
    }
  }, [location.pathname]);

  if (isInitialLogin) {
    return <EmailForm initialEmail={emailAddress} />;
  }

  return (
    <div>
      {isError ? (
        <div>
          <p>エラーが発生しました</p>
        </div>
      ) : (
        <div className="sweet-loading">
          <ClipLoader color="#3700b3" />
        </div>
      )}
    </div>
  );
};

export default LineCallback;
