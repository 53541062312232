import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PsychePurchase.css";
import ClipLoader from "react-spinners/ClipLoader";

const PsychePurchase = ({ psycheCount, onBack }) => {
  const [purchaseOptions, setPurchaseOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  // const purchaseOptions = [
  //     { amount: 1, price: 100 },
  //     { amount: 5, price: 300 },
  //     { amount: 10, price: 800 },
  //     { amount: 30, price: 1500 },
  // ];

  const getOption = async () => {
    try {
      const response = await fetch("/api/psyche_purchase_options", {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const option_array = data.map((item) => ({
        option_id: item.option_id,
        amount: item.psyche_number,
        price: item.price,
      }));
      setPurchaseOptions(option_array);
      setIsLoading(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleOptionClick = (index) => {
    navigate("/psyche_payment", {
      state: {
        option_id: purchaseOptions[index].option_id,
        psyche_number: purchaseOptions[index].amount,
        price: purchaseOptions[index].price,
      },
    });
  };

  useEffect(() => {
    getOption();
  }, []);

  return (
    <>
      <div className="mypage_header">
        <button className="back-button" onClick={onBack}>
          ＜ マイページ
        </button>
      </div>

      <div className="psyche-purchase">
        <h2>プシケ購入</h2>
        <div className="current-psyche">
          <div>現在の所持数</div>
          <div className="psyche-count-container">
            <div className="psyche-count">{psycheCount}</div>
            <div className="psyche-count-unit">プシケ <img src="/icons/psyche.png" className="psyche-icon"/></div>{" "}
          </div>
        </div>
        {isLoading ? (
          <div className="sweet-loading">
            <ClipLoader color="#3700b3" />
          </div>
        ) : (
          <div className="purchase-options">
            {purchaseOptions.map((option, index) => (
              <div
                className="purchase-option"
                key={index}
                onClick={() => handleOptionClick(index)}
              >
                <div className="option-amount"><img src="/icons/psyche.png" className="psyche-icon"/> {option.amount}</div>
                <div className="option-price">¥{option.price}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PsychePurchase;
