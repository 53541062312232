import React from "react";
import "./PictureDetail.css";
import { base64ToBlob } from "../utils/utils";

const PictureDetail = ({ image, onBack }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        const mimeType = "image/png";
        const blob = base64ToBlob(image, mimeType);
        const file = new File([blob], "shared-image.png", { type: mimeType });
        await navigator.share({
          title: "SoulShotを使ったよ",
          text: "SoulShotを使って写真を撮りました！",
          url: "https://soulshot.sekaie.in/",
          files: [file],
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("お使いのブラウザでは共有機能がサポートされていません");
    }
  };

  return (
    <div className="picture-detail">
      <button className="back-button" onClick={onBack}>
        ＜ マイギャラリー
      </button>
      <div className="image-container">
        <img
          src={`data:image/png;base64,${image}`}
          alt="Selected"
          className="selected-image"
        />
      </div>
      <div className="action-buttons">
        <button className="action-button" onClick={handleShare}>
          共有
        </button>
      </div>
    </div>
  );
};

export default PictureDetail;
