import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./GetPictureScreen.css";
import { useTranslation } from 'react-i18next'

// URLからpicture_idを取得して

const GetPictureScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()
    const pictureRef = useRef(null);
    const [isCanShare, setIsCanShare] = useState(false);
    const [data, setData] = useState({});
    const pictureID = location.state?.pictureID;
    const picture = location.state?.pictureSrc;
    const qrcode_img = location.state?.qrcodeSrc;
    const spt_id = location.state?.spt_id;

    const downloadPicture = () => {
        // ここでダウンロードリンクの設定を行う
        const link = document.createElement("a");
        link.href = picture;
        link.download = "downloaded-picture.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        navigate('/thanks', {
            state: {
                qrcodeSrc: qrcode_img,
                spt_id: spt_id,
            },
        });
    };

    const share = async () => {
        try {
            await navigator.share(data);
        } catch (error) {
            // 失敗時時アクション
            console.error('共有に失敗しました', error);
        }
    };

    const prepareShareData = async () => {
        if (pictureRef.current) {
            const imageUrl = pictureRef.current.src; // pictureRefから画像のURLを取得
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob(); // Blobとして画像を取得

                // BlobからFileオブジェクトを作成
                const fileName = imageUrl.split('/').pop(); // URLからファイル名を抽出
                const file = new File([blob], fileName, { type: blob.type }); // BlobのtypeからMIMEタイプを設定

                setData({
                    title: "SoulShotを使ったよ",
                    text: "SoulShotを使って写真を撮りました！",
                    url: "https://soulshot.sekaie.in/",
                    files: [file],
                });
            } catch (error) {
                console.error('画像の取得に失敗しました', error);
            }
        }
    };

    useEffect(() => {
        if (pictureRef.current) {
            prepareShareData();
        }
    }, []);

    useEffect(() => {
        setIsCanShare("canShare" in navigator && navigator.canShare(data));
    }, [data]);

    return (
        <div className="get-picture-container">
            <div className="picture-container">
                {picture ? ( // 修正: 正しい変数名を使用
                    <img ref={pictureRef} src={picture} alt="picture" className="picture" />
                ) : (
                    <p>No image found.</p>
                )}
            </div>
            <div className="download-button-container">
                <button onClick={downloadPicture} className="download-picture-button">{t('保存')}</button>
                <button onClick={share} disabled={!isCanShare} type="button">
                    共有
                </button>
            </div>
        </div>
    );
};

export default GetPictureScreen;
