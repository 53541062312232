import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ThanksPage.css";
import { useTranslation } from 'react-i18next'

const ThanksPageCash = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation()
    const qrcode_img = location.state?.qrcodeSrc;
    const spt_id = location.state?.spt_id;

    const navigate = useNavigate();
    const TakePictureAgain = () => {
        navigate(`/camera?spt_id=${spt_id}`);
    };

    return (
        <div className="thanks-page-container">
            <p className="thanks-message">Thank You!</p>
            {/* <img src={qrcode_img} alt="qrcode" className="qrcode"/>
            <p className="thanks-message">QRコードをスタッフに見せてチェキをもらってください</p> */}
            <button onClick={TakePictureAgain} className="take-picture-again-button">{t('もう一度撮る')}</button>
        </div>
    );
};

export default ThanksPageCash;
