import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "swiper/swiper-bundle.min.css";
import "./SwiperComponent.css";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

import swiperImage01 from "../assets/images/summer_vacation_eyecatch.png";
import swiperImage02 from "../assets/images/view_before.png";
import swiperImage03 from "../assets/images/view_after.png";
import swiperImage04 from "../assets/images/youfie_before.png";
import swiperImage05 from "../assets/images/youfie_after.png";
import swiperImage06 from "../assets/images/selfie_after.png";
import swiperImage07 from "../assets/images/trip_after.png";
import swiperImage08 from "../assets/images/event_after.png";
import swiperImage09 from "../assets/images/graduation_after.png";
import swiperImage10 from "../assets/images/vision_sekaie.png";

export default ({ forLanding }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "30%",
        "margin-top": "40px",
        overflow: "hidden",
      }}
    >
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {forLanding && (
          <SwiperSlide>
            <img src={swiperImage01} alt="Image01" />
          </SwiperSlide>
        )}

        <SwiperSlide>
          <img src={swiperImage02} alt="Image02" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiperImage03} alt="Image03" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiperImage04} alt="Image04" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiperImage05} alt="Image05" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={swiperImage06} alt="Image06" />
        </SwiperSlide>

        {forLanding && (
          <>
            <SwiperSlide>
              <img src={swiperImage07} alt="Image07" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={swiperImage08} alt="Image08" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={swiperImage09} alt="Image09" />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};
