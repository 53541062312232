import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config"; // i18nをインポート
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";

// 環境変数からトラッキングIDを取得
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

// Google Analyticsの初期化
ReactGA.initialize(TRACKING_ID);

// import { register } from './serviceWorkerRegistration'; // Service Workerをインポート

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// Service Workerを登録する
// register();

// Performance measurements
reportWebVitals();
