import React from "react";
import { useLocation } from "react-router-dom";

const Test = () => {
    const location = useLocation();
    const testImage = location.state?.testImage;

    return (
        <img src={testImage} alt="test" />
    );
};

export default Test;
