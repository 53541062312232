import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import PictureCheckScreen from "./screens/PictureCheck";
import GetPictureScreen from "./pages/GetPictureScreen";
import GetPictureByCashScreen from "./pages/GetPictureByCashScreen";
import WaitingScreen from "./pages/WaitingScreen";
import ThanksPage from "./pages/ThanksPage";
import ThanksPageCash from "./pages/ThanksPageCash";
import StaffPage from "./pages/StaffPage";
import MainPage from "./screens/MainPage";

/*３週目に入って追加しているページ*/
import LandingPage from "./screens/Landing";
import LoginPage from "./screens/Login";
import CameraScreen from "./screens/Camera";
import PictureShareScreen from "./screens/PictureShare";
import LineCallback from "./screens/LineCallback";
import PsychePurchase from "./screens/PsychePurchase";
import PsychePayment from "./screens/PsychePayment";
import MyPage from "./screens/MyPage";
import Test from "./pages/Test";
import TestForMultiLanguage from "./pages/TestForMultiLanguage";
import "./App.css";

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    // ランディングページ（/）のトラッキング
    if (location.pathname === "/") {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location]);
}

function App() {
  usePageViews();

  return (
    <Routes>
      <Route path="/camera" element={<CameraScreen />} />
      <Route path="/test" element={<Test />} />
      <Route path="/picture_check" element={<PictureCheckScreen />} />
      {/* <Route path="/purchase" element={<PurchaseScreen />} /> */}
      <Route path="/acquisition" element={<GetPictureScreen />} />
      <Route path="/acquisition_cash" element={<GetPictureByCashScreen />} />
      <Route path="/waiting" element={<WaitingScreen />} />
      <Route path="/thanks" element={<ThanksPage />} />
      <Route path="/thanks_cash" element={<ThanksPageCash />} />
      <Route path="/staff_page" element={<StaffPage />} />
      <Route
        path="/test_for_multi_language"
        element={<TestForMultiLanguage />}
      />
      <Route path="/main" element={<MainPage />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/picture_share" element={<PictureShareScreen />} />
      <Route path="/line_callback" element={<LineCallback />} />
      <Route path="/psyche_purchase" element={<PsychePurchase />} />
      <Route path="/psyche_payment" element={<PsychePayment />} />
      <Route path="/my_page" element={<MyPage />} />
      {/* Add more routes as needed */}
    </Routes>
  );
}

export default App;
