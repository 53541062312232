export class InsufficientPsycheError extends Error {
  constructor() {
    super("Insufficient psyche"); // デフォルトメッセージを設定
    this.name = "InsufficientPsycheError"; // エラー名をカスタムエラーの名前に設定
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

// api.js
export const getUserDetail = async () => {
  const response = await fetch("/api/users/detail", {
    method: "GET",
    credentials: "include", // To send cookies with the request
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user details");
  }
  return response.json();
};

export const getPhotoSessionDetail = async (soulID) => {
  // Construct the query parameters
  const queryParams = new URLSearchParams({
    soul_id: soulID,
  });

  // Make the API call to /api/photo_sessions/detail
  const response = await fetch(`/api/photo_sessions/detail?${queryParams}`, {
    method: "GET",
    credentials: "include", // Include credentials (cookies) in the request
  });

  if (!response.ok) {
    throw new Error(
      `Failed to fetch photo session details: ${response.statusText}`
    );
  }

  return response.json();
};

export const postPhotoSession = async (soulID, isTutorial, isEvent) => {
  const requestBody = JSON.stringify({
    soul_id: soulID,
    is_tutorial: isTutorial,
    is_event: isEvent,
  });

  // Make the API call to /api/photo_sessions
  const response = await fetch("/api/photo_sessions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include", // Include credentials (cookies) in the request
    body: requestBody,
  });

  if (!response.ok) {
    const errorData = await response.json();
    if (errorData.detail === "Insufficient psyche") {
      throw new InsufficientPsycheError();
    }
    throw new Error(`Failed to start photo session: ${response.statusText}`);
  }

  return response.json();
};

export const getMaintenanceCheck = async () => {
  const response = await fetch("/api/maintenance_check", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch maintenance status");
  }

  return response.json();
}
