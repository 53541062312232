import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./PictureShare.css";
import { base64ToBlob } from "../utils/utils";

const PictureShare = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedImageSrc = location.state?.selectedImageSrc;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        const mimeType = "image/png";
        const blob = base64ToBlob(selectedImageSrc, mimeType);
        const file = new File([blob], "shared-image.png", { type: mimeType });
        await navigator.share({
          title: "SoulShotを使ったよ",
          text: "SoulShotを使って写真を撮りました！",
          url: "https://soulshot.sekaie.in/",
          files: [file],
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("お使いのブラウザでは共有機能がサポートされていません");
    }
  };

  const handleReturnToMain = () => {
    navigate("/main");
  };

  return (
    <div className="picture-share-container">
      {selectedImageSrc ? (
        <>
          <img
            src={`data:image/png;base64,${selectedImageSrc}`}
            alt="Selected"
            className="selected-picture"
          />
          <p className="confirmation-message">
            写真がマイギャラリーに保存されました
          </p>
          <div className="buttons-container">
            <button onClick={handleShare} className="share-button">
              共有
            </button>
            <button onClick={handleReturnToMain} className="return-button">
              トップに戻る
            </button>
          </div>
        </>
      ) : (
        <p className="error-message">
          画像が見つかりません。もう一度お試しください。
        </p>
      )}
    </div>
  );
};

export default PictureShare;
