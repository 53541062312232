import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CheckoutForm.css";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next'

const CheckoutForm = ({price}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()

    const stripe = useStripe();
    const elements = useElements();


    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {
            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: "https://soulshot.sekaie.in/api/acquisition",
                },
                redirect: "if_required"
            });


            if (result.error) {
                // Show error to your customer (for example, payment details incomplete)
                console.log(result.error.message);
            } else {
                console.log("Payment succeeded");
                
                // マイぺージに戻る
                setTimeout(() => {
                    navigate('/main');
                }, 1000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="purchase-screen-container">
                <p className="price-info">{price}{t('円（税込）')}</p>
                <PaymentElement className="payment-element"/>
                <button disabled={!stripe} id="purchase-button" className="purchase-button">{t('購入')}</button>
            </div>
        </form>
    );
};

export default CheckoutForm;
