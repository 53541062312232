import React from 'react'
import { useTranslation } from 'react-i18next'

const TestForMultiLanguage = () => {
    const { t, i18n } = useTranslation()

    return (
        <div>
            <h1>{t('こんにちは!')}</h1>
            <p>{t('早起きは三文の徳')}</p>
            <div>{i18n.language === 'ko' ? '韓国語' : '日本語'}</div>
            <button
                type='button'
                title='change to Korean.'
                onClick={() => i18n.changeLanguage('ko')}
            >
                韓国語
            </button>
            <button
                type='button'
                title='Change to Japanese'
                onClick={() => i18n.changeLanguage('ja')}
            >
                日本語
            </button>
        </div>
    )
}

export default TestForMultiLanguage
