import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ConsumePsycheForPicture.css";
import ClipLoader from "react-spinners/ClipLoader";
import {
  getPhotoSessionDetail,
  getUserDetail,
  postPhotoSession,
  getMaintenanceCheck,
  InsufficientPsycheError,
} from "../utils/api";

const Callout = ({ title, message }) => {
  return (
    <div className="callout">
      <h3>{title}</h3>
      <p>{message}</p>
    </div>
  );
};

const psychePerPhoto = 2;

const ConsumePsycheForPicture = ({ character, onBack }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPhotoSessionActive, setIsPhotoSessionActive] = useState(false);
  const [tutorialConsumed, setTutorialConsumed] = useState(false);
  const [waterMarkedPictures, setWaterMarkedPictures] = useState([]);

  useEffect(() => {
    const fetchPhotoSessionDetail = async () => {
      try {
        const photoSession = await getPhotoSessionDetail(character.soul_id);
        console.log("Photo session detail response:", photoSession);

        const user = await getUserDetail();
        console.log("User detail response:", user);

        setIsPhotoSessionActive(photoSession.is_photo_session_active);
        setWaterMarkedPictures(photoSession.water_marked_pictures);
        setTutorialConsumed(user.tutorial_consumed);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching photo session details:", error);
      }
    };

    fetchPhotoSessionDetail();
  }, [character.soul_id]);

  const handleStartPhotoClick = async (isEvent) => {
    try {
      const maintenanceCheck = await getMaintenanceCheck();
      if (maintenanceCheck.is_maintenance) {
        alert("ただいまメンテナンス中です。ご迷惑をおかけして申し訳ございません。");
        return;
      }

      if (!isPhotoSessionActive) {
        await postPhotoSession(character.soul_id, !tutorialConsumed, isEvent);
      }

      if (waterMarkedPictures.length > 0) {
        navigate("/picture_check", {
          state: {
            soul_id: character.soul_id,
            pictures: waterMarkedPictures,
          },
        });
      } else {
        // Navigate to the CameraScreen page with soul_id as query parameter
        navigate(`/camera?soul_id=${character.soul_id}`, {
          state: {
            pictures: waterMarkedPictures,
          },
        });
      }
    } catch (error) {
      if (error instanceof InsufficientPsycheError) {
        alert("プシケが不足しています。マイページよりご購入ください。");
      }
      console.error("Error starting photo session:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="sweet-loading">
        <ClipLoader color="#3700b3" />
      </div>
    );
  }

  const photoSessionActiveComponent = (
    <>
      <div className="message">前回の写真撮影がまだ終わっていません</div>
      <button className="start-photo-button" onClick={handleStartPhotoClick}>
        前回の続きから開始
      </button>
    </>
  );

  const tutorialComponent = (
    <>
      <div className="message">まずはウェルカム撮影から始めましょう</div>
      <p className="limited-time">プシケは消費されません（無料）</p>
      <button
        className="start-limited-photo-button"
        onClick={() => handleStartPhotoClick(true)}
      >
        ウェルカム撮影を開始
      </button>
    </>
  );

  const consumeComponent = (
    <>
      <div className="message">
        写真撮影にはプシケを{psychePerPhoto}つ消費します
      </div>
      <p className="limited-time">期間限定！！ 〜8/31</p>
      <button
        className="start-limited-photo-button"
        onClick={() => handleStartPhotoClick(true)}
      >
        夏休み中のキャラと撮影開始
      </button>
      <button
        className="start-photo-button"
        onClick={() => handleStartPhotoClick(false)}
      >
        ソウル撮影を開始
      </button>
    </>
  );

  return (
    <div className="consume-psyche-page">
      <button className="back-button" onClick={onBack}>
        ＜ キャラクター一覧
      </button>
      <img src={character.image} alt={character.name} />
      <div className="character-name">{character.name}</div>
      <p className="sekai-info">{character.sekai_info}</p>

      {isPhotoSessionActive
        ? photoSessionActiveComponent
        : tutorialConsumed
        ? consumeComponent
        : tutorialComponent}
      {/* <Callout
        title="ヒント"
        message="上半身のみを撮影すると、写真の仕上がりがより良くなります"
      /> */}
    </div>
  );
};

export default ConsumePsycheForPicture;
