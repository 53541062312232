import React from 'react';
import './Tabs.css';

const Tabs = ({ activeTab, setActiveTab }) => {
    return (
        <div className="tabs">
            <button 
                className={`tab ${activeTab === '写真撮影' ? 'active' : ''}`} 
                onClick={() => setActiveTab('写真撮影')}
            >
                写真撮影
            </button>
            <button 
                className={`tab ${activeTab === 'マイページ' ? 'active' : ''}`} 
                onClick={() => setActiveTab('マイページ')}
            >
                マイページ
            </button>
        </div>
    );
};

export default Tabs;
