import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./PictureCheck.css";

const PictureCheck = () => {
  // const [pictures, setPictures] = useState([]);
  const [tryNumber, setTryNumber] = useState(3);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { soul_id, pictures } = location.state;

  useEffect(() => {
    // Calculate try number based on the number of saved pictures
    const remainingTries = 3 - pictures.length;
    setTryNumber(remainingTries);
  }, []);

  const handleRetake = () => {
    navigate(`/camera?soul_id=${soul_id}`, {
      state: {
        pictures: pictures,
      },
    });
  };

  const handleConfirm = async () => {
    if (selectedPicture === null || selectedIndex === null) {
      alert("写真を選択してください");
    }
    if (selectedPicture !== null && selectedIndex !== null) {
      try {
        const response = await fetch("/api/photo_sessions/complete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Cookie: `session_id=${document.cookie}`,
          },
          credentials: "include",
          body: JSON.stringify({
            soul_id: soul_id,
            selected_try_number: selectedIndex + 1, // API expects index starting from 1
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const selectedImageSrc = data.picture;

          // Navigate to PictureShare page with selected picture data
          navigate("/picture_share", {
            state: {
              selectedImageSrc: selectedImageSrc,
            },
          });
        } else {
          console.error("Error:", await response.text());
        }
      } catch (error) {
        console.error("Error sending completion request:", error);
      }
    }
  };

  const selectPicture = (index) => {
    const elements = document.getElementsByClassName("watermarked-picture");

    // Remove the border from all images
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.border = "2px solid transparent";
    }

    // Add the border to the selected image
    elements[index].style.border = "2px solid #00bcd4";

    // Save the selected picture and index
    setSelectedPicture(pictures[index]);
    setSelectedIndex(index);
  };

  return (
    <div className="picture-check-container">
      <div className="pictures-container">
        {pictures.map((base64String, index) => (
          <div
            key={index}
            className="picture-wrapper"
            onClick={() => selectPicture(index)}
          >
            <img
              src={`data:image/png;base64,${base64String}`}
              alt={`Watermarked ${index + 1}`}
              className="watermarked-picture"
            />
          </div>
        ))}
      </div>
      {tryNumber === 0 ? (
        <p className="instruction">お気に入りの写真を１つ選んでください</p>
      ) : (
        <p className="try-number">残り撮影回数 {tryNumber}</p>
      )}
      <div className="buttons-container">
        {tryNumber !== 0 && (
          <button onClick={handleRetake} className="retake-button">
            撮り直す
          </button>
        )}
        <button onClick={handleConfirm} className="confirm-button">
          写真を決定
        </button>
      </div>
    </div>
  );
};

export default PictureCheck;
