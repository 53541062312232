import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { FaCameraRotate } from "react-icons/fa6";
import "./Camera.css";
import SwiperComponent from "../components/SwiperComponent";

const InstructionPopup = ({ onClickButton }) => {
  return (
    <div className="tutorial-popup">
      <div className="popup-content">
        <h2>撮影のヒント</h2>
        <SwiperComponent forLanding={false} />
        <div className="popup-instruction">
          <p className="title">💡ヒント</p>
          <p className="point">1. キャラのための余白を残してね！</p>
          <p className="point">2. 相手を撮ってあげのも良い！</p>
          <p className="point">3. 人が写ってなくて大丈夫！</p>
          <p className="point">4. 自撮りは恥ずかしがらない！</p>
          <br />
        </div>
        <div className="popup-buttons">
          <button onClick={onClickButton}>撮影開始</button>
        </div>
      </div>
    </div>
  );
};

const CameraScreen = () => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  const [useFrontCamera, setUseFrontCamera] = useState(true);

  // soul_id
  const urlParams = new URLSearchParams(window.location.search);
  const soul_id = urlParams.get("soul_id");

  const location = useLocation();
  const { pictures } = location.state;

  // モーダルウィンドウの表示・非表示を管理するためのstate
  const [showPopup, setShowPopup] = useState(true);

  // カメラ画面の縦横比を決めるためのコード
  const determinePictureWidth = (isLandscape) => {
    const pictureWidth = isLandscape ? 1024 : 768;
    return pictureWidth;
  };

  const determinePictureHeight = (isLandscape) => {
    const pictureHeight = isLandscape ? 768 : 1024;
    return pictureHeight;
  };

  const getVideoStream = async (useFront = true) => {
    try {
      const isLandscape = window.innerWidth > window.innerHeight;
      const pictureWidth = determinePictureWidth(isLandscape);
      const pictureHeight = determinePictureHeight(isLandscape);

      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      let deviceId =
        videoDevices.length > 1
          ? useFront
            ? videoDevices[1].deviceId
            : videoDevices[0].deviceId
          : undefined;

      // constraintsの初期定義
      let constraints = {
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
        },
      };

      if (isLandscape) {
        constraints.video.width = { exact: pictureWidth };
        constraints.video.height = { exact: pictureHeight };
      } else {
        constraints.video.width = { exact: pictureHeight }; // 縦画面のときに幅と高さを交換
        constraints.video.height = { exact: pictureWidth };
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      // エラーハンドリングが必要な場合ここに記述
    }
  };

  const switchCamera = () => {
    setUseFrontCamera((prevUseFrontCamera) => !prevUseFrontCamera);
  };

  const takePicture = async (useFrontCamera) => {
    const isLandscape = window.innerWidth > window.innerHeight;
    const pictureWidth = determinePictureWidth(isLandscape);
    const pictureHeight = determinePictureHeight(isLandscape);

    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");

      canvasRef.current.width = pictureWidth;
      canvasRef.current.height = pictureHeight;

      if (!useFrontCamera) {
        // 画像が左右反転するように設定
        context.translate(pictureWidth, 0);
        context.scale(-1, 1);
      }

      // Draw the video frame on the canvas
      const stream_width = videoRef.current.videoWidth;
      const stream_height = videoRef.current.videoHeight;

      context.drawImage(
        videoRef.current,
        0,
        0,
        stream_width,
        stream_height,
        0,
        0,
        pictureWidth,
        pictureHeight
      );

      if (!useFrontCamera) {
        // 画像の左右反転を元に戻す
        context.translate(pictureWidth, 0);
        context.scale(-1, 1);
      }

      // Create a blob for the video frame
      canvasRef.current.toBlob(async (blobVideoFrame) => {
        const reader = new FileReader();
        reader.readAsDataURL(blobVideoFrame);
        reader.onloadend = async () => {
          const base64data = reader.result.split(",")[1]; // Extract base64 string

          const payload = {
            soul_id: soul_id,
            raw_picture: base64data,
            is_selfie: useFrontCamera,
            is_landscape: isLandscape,
          };

          const blobVideoFrameURL = URL.createObjectURL(blobVideoFrame);

          navigate("/waiting", {
            state: {
              combinedImageSrc: blobVideoFrameURL,
            },
          });

          const response = await fetch("/api/photo_sessions/img2img", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // 'Cookie': `session_id=${document.cookie}`
            },
            credentials: "include",
            body: JSON.stringify(payload),
          });

          if (response.ok) {
            const data = await response.json();
            const waterMarkedPicture = data.water_marked_picture;

            navigate("/picture_check", {
              state: {
                soul_id: soul_id,
                pictures: [...pictures, waterMarkedPicture],
              },
            });
          } else {
            const errorMessage = await response.text();
            console.error("Error:", errorMessage);
            // Handle the error message accordingly
          }
        };
      }, "image/png");
    }
  };

  const adjustLayoutForOrientation = () => {
    const isLandscape = window.innerWidth > window.innerHeight;
    let containerWidth, containerHeight;

    if (isLandscape) {
      containerHeight = window.innerHeight;
      containerWidth = containerHeight * 1.333;
    } else {
      containerWidth = window.innerWidth;
      containerHeight = containerWidth * 1.333; // Correctly aiming for height to be 1.33 times of width
    }

    if (containerRef.current) {
      containerRef.current.style.width = `${containerWidth}px`;
      containerRef.current.style.height = `${containerHeight}px`;
      containerRef.current.style.float = isLandscape ? "left" : "none";
    }
  };

  const doGetVideoStream = () => {
    getVideoStream(useFrontCamera);
  };

  const requestCameraPermission = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      } catch (error) {
        console.error("Error requesting camera permission:", error);
      }
    }
  };

  useEffect(() => {
    // 初回レンダリング時に実行
    requestCameraPermission();
  }, []);

  useEffect(() => {
    getVideoStream(useFrontCamera);
    adjustLayoutForOrientation();
    videoRef.current.style.webkitTransform = useFrontCamera
      ? "scaleX(1)"
      : "scaleX(-1)";
    videoRef.current.style.transform = useFrontCamera
      ? "scaleX(1)"
      : "scaleX(-1)";

    window.addEventListener("resize", adjustLayoutForOrientation);
    window.addEventListener("orientationchange", adjustLayoutForOrientation);
    window.addEventListener("resize", doGetVideoStream);
    window.addEventListener("orientationchange", doGetVideoStream);

    return () => {
      window.removeEventListener("resize", adjustLayoutForOrientation);
      window.removeEventListener(
        "orientationchange",
        adjustLayoutForOrientation
      );
      window.removeEventListener("resize", doGetVideoStream);
      window.removeEventListener("orientationchange", doGetVideoStream);
    };
  }, [useFrontCamera]);

  const handleBack = () => {
    navigate("/main");
  };

  return (
    <div className="main-container">
      {showPopup && (
        <InstructionPopup onClickButton={() => setShowPopup(false)} />
      )}
      {/* New wrapping container */}
      <div className="stream-container" ref={containerRef}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          className="video-element"
        ></video>
      </div>
      <div className="camera-footer">
        <button
          type="button"
          className="camera-back-button"
          onClick={() => handleBack()}
        >
          {/* <img src="/icons/black.png" alt="BackButton" /> */}
          <FaChevronLeft style={{ color: "white", fontSize: "36px" }} />
        </button>
        <button
          onClick={() => takePicture(useFrontCamera)}
          className="capture-button"
        >
          <div className="camera-inner-circle"></div>
        </button>
        <button onClick={switchCamera} className="camera-switch-button">
          <FaCameraRotate style={{ color: "white", fontSize: "45px" }} />
        </button>
        {/* <button onClick={togglePopup} className="popup-button">
                    {showPopup ? '' : <img src="/icons/exclamation.svg" alt="Popup" /> }
                    </button> */}
        {/* Adding a hidden canvas element to capture the picture */}
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </div>
      {/* <div className="popup-container">
                {showPopup && (
                    <div className="popup-window">
                        <button onClick={togglePopup} className="popup-close-button">×</button>
                        <p className="explanation">より大きな画面で快適な撮影は<a onClick={openModal}>こちら</a>から</p>
                    </div>
                )}
                <PWAExplanationModal isOpen={isModalOpen} onClose={closeModal}>
                </PWAExplanationModal>
            </div> */}
    </div>
  );
};

export default CameraScreen;
