import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./StaffPage.css";
import { useTranslation } from 'react-i18next'

const StaffPage = () => {
    const resultTextRef = useRef(null);
    const sendPaymentConfirmButtonRef = useRef(null);
    const downloadButtonRef = useRef(null);
    const { t, i18n } = useTranslation()

    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const SendPaymentConfirm = () => {
        // 支払いを確認ボタンを非アクティブ化
        sendPaymentConfirmButtonRef.current.disabled = true;

        // ローディングアニメーションを表示
        setIsLoading(true);

        try {
            // Send a payment confirmation to the server
            console.log('Sending payment confirmation...')

            // クエリパラメータからpicture_idを取得
            let picture_id = query.get("picture_id");

            // DBのis_paidカラムをtrueに更新する
            const formdata = new FormData();
            formdata.append("picture_id", picture_id);
            fetch("https://soulshot.sekaie.in/api/set_true_for_is_paid_status", {
                method: "POST",
                body: formdata,
            })
            .then(response => response.text())
            .then(data => {
                console.log(data);
                if (data != "Success") {
                    throw new Error("Failed to download image.");
                }

                // ローディングアニメーションを非表示
                setIsLoading(false);

                // 支払い情報の登録が完了した旨をページ内に表示
                resultTextRef.current.innerText = t('支払い情報が登録されました。下のダウンロードボタンからツーショット写真をダウンロードして、印刷したチェキをお渡ししてください。');

                // ダウンロードボタンをアクティブ化
                downloadButtonRef.current.disabled = false;

                return true;
            })
            .catch(error => {
                console.error("Failed to send payment confirmation: ", error);

                // ローディングアニメーションを非表示
                setIsLoading(false);

                // エラーが発生した旨をページ内に表示
                resultTextRef.current.innerText = t('支払い情報の登録に失敗しました。もう一度お試しください。');

                // 支払いを確認ボタンをアクティブ化
                sendPaymentConfirmButtonRef.current.disabled = false;

                return false;
            });

            // // テスト用に3秒待機
            // setTimeout(() => {
            //     // ローディングアニメーションを非表示
            //     setIsLoading(false);

            //     // 支払い情報の登録が完了した旨をページ内に表示
            //     resultTextRef.current.innerText = "支払い情報が登録されました。印刷したチェキをお渡ししてください。";

            //     return true;
            // }, 3000);
        } catch (error) {
            console.error("Failed to send payment confirmation: ", error);

            // ローディングアニメーションを非表示
            setIsLoading(false);

            // エラーが発生した旨をページ内に表示
            resultTextRef.current.innerText = t('支払い情報の登録に失敗しました。もう一度お試しください。');

            // 支払いを確認ボタンをアクティブ化
            sendPaymentConfirmButtonRef.current.disabled = false;

            return false;
        }
    }

    const DownloadImage = () => {
        try {
            // クエリパラメータからpicture_idを取得
            let picture_id = query.get("picture_id");

            // ツーショット写真をダウンロード
            console.log(picture_id);
            window.open(`https://soulshot.sekaie.in/api/get_picture_from_qrcode?picture_id=${picture_id}`);
        } catch (error) {
            console.error("Failed to download image: ", error);
        }
    }

    useEffect(() => {
        // 初回レンダリング時に実行
        downloadButtonRef.current.disabled = true;
    }, []);

    return (
        <div className="staff-page-container">
            <button ref={sendPaymentConfirmButtonRef} onClick={SendPaymentConfirm} className="send-payment-confirm-button">
                {isLoading ? <div className="spinner"></div> : t('支払いを確認')}
            </button>
            <p ref={resultTextRef} className="result-text"></p>
            <button ref={downloadButtonRef} onClick={DownloadImage} className="download-button">{t('ダウンロード')}</button>
        </div>
    );
};

export default StaffPage;
