import React, { useState, useEffect } from "react";
import "./Landing.css";
import titleLogo from "../assets/images/title-logo.png";
import SwiperComponent from "../components/SwiperComponent";
import { FaCamera } from "react-icons/fa6";

const SoulCard = ({ image, name, link }) => {
  return (
    <a href={link} className="card-link">
      <div className="card">
        <img src={image} alt={name} className="card-image" />
        <div className="card-name">{name}</div>
      </div>
    </a>
  );
};

const SoulsMain = () => {
  const [souls, setSouls] = useState([]);

  useEffect(() => {
    const fetchSouls = async () => {
      try {
        const response = await fetch("/api/souls");
        const data = await response.json();
        console.log("Fetched data:", data); // Debugging log

        // APIのレスポンスを適切にマッピング
        const mappedSouls = data.map((soul) => ({
          name: soul.soul_name,
          image: soul.picture_url,
          link: "/login",
        }));
        console.log("Mapped souls:", mappedSouls); // Debugging log
        setSouls(mappedSouls);
      } catch (error) {
        console.error("Error fetching souls:", error);
      }
    };

    fetchSouls();
  }, []);

  return (
    <div className="landing-container">
      <img src={titleLogo} alt="Logo" className="logo" />
      <SwiperComponent forLanding={true} />
      {/* <p className="limited-time-text">
        <p className="time-limit">夏休みイベントは 8/31まで！</p>
      </p> */}
      <p
        style={{
          "margin-top": "40px",
          fontWeight: "bold",
          fontFamily: "sans-serif",
          fontSize: "22px",
          color: "#1D2088",
        }}
      >
        まずは好きなキャラを選ぼう！
      </p>
      <div className="soul-array-main-container">
        {souls.map((soul) => (
          <SoulCard
            key={soul.name}
            name={soul.name}
            image={soul.image}
            link={soul.link}
          />
        ))}
      </div>
    </div>
  );
};

export default SoulsMain;
