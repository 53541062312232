import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import ClipLoader from "react-spinners/ClipLoader";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeAPIKey = process.env.REACT_APP_STRIPE_API_KEY;
const stripePromise = loadStripe(stripeAPIKey);

function PsychePayment() {
  const location = useLocation();
  const option_id = location.state.option_id;
  const psyche_number = location.state.psyche_number;
  const price = location.state.price;
  const [clientSecret, setClientSecret] = useState(null);

  const getClientSecret = async () => {
    try {
      const response = await fetch("/api/payment_intents/psyche", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ option_id: option_id }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setClientSecret(data.clientSecret);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    getClientSecret();
  }, []);

  if (!clientSecret) {
    return (
      <div className="sweet-loading">
        <ClipLoader color="#3700b3" />
      </div>
    );
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm price={price} />
    </Elements>
  );
}

export default PsychePayment;
