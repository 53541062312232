import React from "react";
import "./Login.css";
import lineLogo from "../assets/images/line-logo.png";

const Login = () => {
  const handleLogin = async () => {
    try {
      const response = await fetch("/api/login/line", {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const redirectURL = data.redirect_url;

      window.location.href = redirectURL;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      alert("Login failed");
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">ログイン</h1>

        <button className="login-button" onClick={handleLogin}>
          <img src={lineLogo} alt="LINE" className="line-icon" />
          <span>LINEでログイン</span>
        </button>
        <div className="login-notice-card">
          <p className="login-notice">
            LINEに登録されているメールアドレスは、本サービスにおいてトラブルが発生した際の連絡手段や迅速な対応を行う目的に利用し、第三者等への提供は致しません。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
